import axios from 'axios'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../redux/store'
import { selectCurrentCustomerNumber } from '../../../redux/customer'
import PracticeTraining from './Training'
import { ErrorNotify } from '../../../components/Notify.styled';
import { CustomerTraining, CustomerTrainingMetadata, UserTrainingMetadata } from '../../../types'
import constants from '../../../constants'

const PracticeTrainingWrapper = () => {
  const customerNumber = useAppSelector(selectCurrentCustomerNumber)

  const [allCustomerTrainings, setAllCustomerTrainings] = useState<CustomerTraining[] | null>(null)
  const [customerTrainingMetadata, setCustomerTrainingMetadata] = useState<Partial<CustomerTrainingMetadata> | null>(null)
  const [userTrainingMetadata, setUserTrainingMetadata] = useState<UserTrainingMetadata[] | null>(null)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleCloseSnackbar = () => {
    setOpenAlert(false)
  }

  const handleOpenSnackbar = (message: string) => {
    setErrorMessage(message)
    setOpenAlert(true)
  }

  const getCustomerTrainings = async () => {
    const query = {
      include: [
        {
          relation: 'customerTraining',
          scope: {
            include: {
              relation: 'training',
              scope: {
                include: {
                  relation: 'ref_item'
                }
              }
            }
          }
        },
        {
          relation: 'user_training_metadata',
          scope: {
            include: {
              relation: 'compliance'
            }
          }
        },
        {
          relation: 'customer_training_metadata',
          scope: {
            include: {
              relation: 'compliance'
            }
          }
        }
      ]
    }

    try {
      const response = await axios.get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
      setAllCustomerTrainings(response?.data?.customerTraining)
      setCustomerTrainingMetadata(response?.data?.customer_training_metadata)
      setUserTrainingMetadata(response?.data?.user_training_metadata)
    } catch (error) {
      console.error(error)
      handleOpenSnackbar('There was an issue retrieving the customer trainings')
    }
  }

  useEffect(() => {
    if (customerNumber) {
      getCustomerTrainings()
    }
  }, [customerNumber])

  if (allCustomerTrainings && customerTrainingMetadata && userTrainingMetadata) {
    return allCustomerTrainings.length
      ? (
          <PracticeTraining 
            allCustomerTrainings={allCustomerTrainings}
            customerTrainingMetadata={customerTrainingMetadata}
            userTrainingMetadata={userTrainingMetadata}
            getCustomerTrainings={getCustomerTrainings}
          />
        )
      : (<iframe className="iframe-sales-page" data-testid="sales-iframe" src={constants.OSHA_HIPAA_TRAINING_SALES_PAGE_IFRAME_URL} />)
  } else {
    return errorMessage ? (
      <ErrorNotify
        open={openAlert}
        onClose={handleCloseSnackbar}
      >
        { errorMessage }
      </ErrorNotify>
    ) : null
  }
}

export default PracticeTrainingWrapper
